import React from 'react'
import { NavLink } from 'react-router-dom';

export default function Inicio() {
  return (
    <div>
    
      <div class="px-4 py-5 my-5 text-center">
        <img
          class="d-block mx-auto mb-4"
          src="logo.png"
          alt=""
          width="72"
          height="57"
        />
        <h1 class="display-5 fw-bold text-body-emphasis">Pre-Registro</h1>
        <div class="col-md-5 mx-auto col-lg-5">
        <form class="p-4 p-md-5 border rounded-3 bg-body-tertiary">
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="floatingInput" placeholder="CC123"/>
            <label for="floatingInput">Nro. Solicitud</label>
          </div>
          {/* <button class="w-100 btn btn-lg btn-primary" type="button">Buscar</button> */}
          <NavLink
              to={{
                pathname: "/",
              }}
              className={({ isActive }) =>
                `w-100 btn btn-lg btn-primary ${
                  isActive ? "active" : ""
                }  link-offset-2 link-underline-opacity-10 link-underline-opacity-100-hover`
              }
            >
              Buscar
            </NavLink>
          <hr class="my-4"/>          
          
          {/* <button class="w-100 btn btn-lg btn-primary" type="button">Nuevo</button> */}
          <NavLink
              to={{
                pathname: "/preregistro",
              }}
              className={({ isActive }) =>
                `w-100 btn btn-lg btn-primary ${
                  isActive ? "active" : ""
                }  link-offset-2 link-underline-opacity-10 link-underline-opacity-100-hover`
              }
            >
              Nuevo
            </NavLink>
          <hr class="my-4"/>
          <small class="text-body-secondary">Pre-registro de solicitud servicio.</small>
        </form>
      </div>
      </div>
    </div>
  );
}
