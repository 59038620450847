import React from 'react'
import { NavLink } from 'react-router-dom';

export default function Preregistro() {
  return (
    <div class="col-md-5 mx-auto col-lg-5">
    
      <div className="px-4 py-5 my-5 text-center border rounded-3 bg-body-tertiary">
      <h1 class="display-10 fw-bold text-body-emphasis">Registro</h1>
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            id="floatingInput"
            placeholder="Nro."
          />
          <label className="floatingInput">Nro.</label>
        </div>
        <div className="form-floating mb-3">
          <input
            type="date"
            className="form-control"
            id="floatingInput"
            placeholder="Fecha Doc."
          />
          <label className="floatingPassword">Fecha Doc.</label>
        </div>
        <div className="form-floating mb-3">
          <select className="form-select" aria-label="Default select example">            
            <option value="1">Aeropuerto 1</option>
            <option value="2">Aeropuerto 2</option>
            <option value="3">Aeropuerto 3</option>
          </select>
          <label className="floatingInput">Locacion</label>
        </div>
        <div className="form-floating mb-3">
          <select className="form-select" aria-label="Default select example">            
            <option value="1">Natural</option>            
          </select>
          <label className="floatingInput">Tipo Cliente</label>
        </div>
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            id="floatingInput"
            placeholder="Nro."
          />
          <label className="floatingInput">RUC/DNI</label>
        </div>
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            id="floatingInput"
            placeholder="Nro."
          />
          <label className="floatingInput">Razon/Nombre</label>
        </div>
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            id="floatingInput"
            placeholder="Nro."
          />
          <label className="floatingInput">Telefono</label>
        </div>
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            id="floatingInput"
            placeholder="Nro."
          />
          <label className="floatingInput">Correo Electronico</label>
        </div>
        <div className="form-floating mb-3">
          <select className="form-select" aria-label="Default select example">            
            <option value="1">Envio de Caja</option>            
            <option value="2">Envio otro</option>            
          </select>
          <label className="floatingInput">Servicio Requerido</label>
        </div>
        {/* <button class="w-100 btn btn-lg btn-primary" type="button">Guardar</button> */}
        <NavLink
              to={{
                pathname: "/",
              }}
              className={({ isActive }) =>
                `w-100 btn btn-lg btn-primary ${
                  isActive ? "active" : ""
                }  link-offset-2 link-underline-opacity-10 link-underline-opacity-100-hover`
              }
            >
              Guardar
            </NavLink>

      </div>      
    </div>
  );
}
