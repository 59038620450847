import React from 'react'
import Inicio from './inicio'
import Preregistro from './preregistro'
import { Route, Routes } from 'react-router-dom'

export default function Main() {
  return (
    <>    
    <Routes>        
      <Route path="/" element={<Inicio />} />
      <Route path="/preregistro" element={<Preregistro />} />
      {/* <Route path="/*" element={<Navigate to="/" />} /> */}
    </Routes>
    </>
  );
}
